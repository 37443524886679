import React, { useState } from 'react';
import { db } from '../../services/firebase';

const GenerateProfileLink = ({ familyId, userId }) => {
  const [link, setLink] = useState('');

  const generateToken = () => {
    const token = Date.now().toString(36) + Math.random().toString(36).substr(2);
    const secureLink = `https://yourapp.com/auto-login?token=${token}`;

    db.collection('families').doc(familyId).collection('profileLinks').doc(userId).set({
      token: token,
      userId: userId,
      familyId: familyId,
      createdAt: new Date(),
      expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
    }).then(() => {
      setLink(secureLink);
    }).catch(error => {
      console.error("Error generating link: ", error);
    });
  };

  return (
    <div>
      <button onClick={generateToken}>Generate Profile Link</button>
      {link && (
        <div>
          <p>Profile Link: <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>
        </div>
      )}
    </div>
  );
};

export default GenerateProfileLink;
