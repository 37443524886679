import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Add this import
import { FaLock } from 'react-icons/fa';

console.log('UserSelection.js')
const UserSelection = () => {
  const { familyId, setSelectedUserId } = useAuth();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {

      if (!familyId) {
        return; // Exit early if familyId is not available
      }
      
      const usersCollection = collection(db, `families/${familyId}/users`);
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(user => user.name);
      setUsers(usersList);
    };

    fetchUsers();
  }, [familyId]);

  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    navigate('/UserPage');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Select User</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {users.map(user => (
          <button
            key={user.id}
            onClick={() => handleUserSelect(user.id)}
            className="bg-blue-500 text-white p-4 rounded-lg text-lg w-full"
          >
            {user.name}
          </button>
        ))}
        
      </div>
      <div className="fixed bottom-4 right-4">
                    {/* Lock Icon */}
                    <Link to="/admin/tile">
                      <FaLock style={{ marginLeft: '10px', fontSize: '2.5rem' }} />
                      </Link>
        </div>

    </div>
    
    
  );
};

export default UserSelection;
