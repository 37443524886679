import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';

const RewardAdmin = () => {
  const { familyId } = useAuth();
  const [rewards, setRewards] = useState([]);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const fetchrewardsUsersAndGroups = async () => {
      try {
        const rewardsQuery = collection(db, `families/${familyId}/rewards`);
        const rewardsSnapshot = await getDocs(rewardsQuery);
        const rewardsData = rewardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRewards(rewardsData);

        const usersQuery = collection(db, `families/${familyId}/users`);
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.name);
        setUsers(usersData);

      } catch (error) {
        console.error('Error fetching rewards and users:', error);
      }
    };

    fetchrewardsUsersAndGroups();
  }, [familyId]);

  const handleUpdatereward = async (reward) => {
    try {
      
      await updateDoc(doc(db, `families/${familyId}/rewards`, reward.id), { ...reward});
      const rewardsQuery = collection(db, `families/${familyId}/rewards`);
      const rewardsSnapshot = await getDocs(rewardsQuery);
      const rewardsData = rewardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRewards(rewardsData);
    } catch (error) {
      console.error('Error updating reward:', error);
    }
  };

  const handleDeletereward = async (rewardId) => {
    try {
      await deleteDoc(doc(db, `families/${familyId}/rewards`, rewardId));
      setRewards(rewards.filter(reward => reward.id !== rewardId));
    } catch (error) {
      console.error('Error deleting reward:', error);
    }
  };

  const handleInputChange = (e, rewardId, field) => {
    const value = e.target.value;
    setRewards(rewards.map(t => t.id === rewardId ? { ...t, [field]: value } : t));
  };

 
  const handleCheckboxChange = (e, rewardId, field) => {
    const value = e.target.checked;
    setRewards(rewards.map(t => t.id === rewardId ? { ...t, [field]: value } : t));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Reward Administration</h1>
      <ul>
        {rewards.map(reward => (
          <li key={reward.id} className="mb-2 p-2 border border-gray-300 rounded flex flex-col">
            <div className="flex justify-between items-center">
              <strong>Reward Name:</strong>
              <input
                type="text"
                value={reward.name || ''}
                onChange={(e) => handleInputChange(e, reward.id, 'name')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            
            <div className="flex justify-between items-center">
              <strong>Assign To:</strong>
              <select
                value={reward.assignedTo || ''}
                onChange={(e) => handleInputChange(e, reward.id, 'assignedTo')}
                className="ml-2 p-1 border border-gray-300 rounded"
              >
                <option value="">Select User or Group</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>User: {user.name}</option>
                ))}

              </select>
            </div>
            <div className="flex justify-between items-center">
              <strong>Points:</strong>
              <input
                type="number"
                value={reward.points || 1}
                onChange={(e) => handleInputChange(e, reward.id, 'points')}
                className="ml-2 p-1 border border-gray-300 rounded"
                min="1"
                max="10"
              />
            </div>
           
           
            <div className="mb-4">
        <label className="block mb-2 flex items-center cursor-pointer">
        <span className="mr-2">Approval Required</span>
          <input
            type="checkbox"
            checked={reward.approvalRequired}
            onChange={(e) => handleCheckboxChange(e, reward.id, 'approvalRequired')}
            className="hidden" // Hide the default checkbox input
          />
          <span className="checkmark"></span> 
          </label>
        </div>
        <div className="mb-4">
            <label className="block mb-2 flex items-center cursor-pointer">
                <span className="mr-2">Multipliable</span>
                <input
                    type="checkbox"
                    checked={reward.multipliable}
                    onChange={(e) => handleCheckboxChange(e, reward.id, 'multipliable')}
                    className="hidden" // Hide the default checkbox input
                    id="multipliable-checkbox"
                />
                <span className="checkmark"></span> 
            </label>
        </div>


            <div className="flex justify-between items-center mt-2">
              <button
                onClick={() => handleUpdatereward(reward)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Update reward
              </button>
              <button
                onClick={() => handleDeletereward(reward.id)}
                className="bg-red-500 text-white p-2 rounded"
              >
                Delete reward
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RewardAdmin;
