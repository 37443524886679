import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import GenerateProfileLink from './components/Admin/GenerateProfileLink';
import AutoLogin from './services/autoLogin';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import FamilyAdmin from './components/Admin/FamilyAdmin';
import TaskList from './components/Tasks/TaskList';
import AddTask from './components/Tasks/AddTask';
// Removed unused import
import TokenLogin from './components/Auth/TokenLogin';
import Profile from './components/Profile';
import UserSelection from './components/UserSelection'; // Import UserSelection
import TaskAdmin from './components/Admin/TaskAdmin'; // Import TaskAdmin
import RewardAdmin from './components/Admin/RewardAdmin'; // Import RewardAdmin
import TaskApproval from './components/Admin/TaskApproval';
import AddReward from './components/Rewards/AddReward';
import UserPage from './components/UserPage';
import RewardList from './components/Rewards/RewardList';
import RewardApproval from './components/Admin/RewardApproval';
import AdminTile from './components/Admin/AdminTile';

function HomePage() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      navigate('/user-selection');
    }
  }, [currentUser, navigate]);

  return <h1>Home Page</h1>;
}

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/tasks"
          element={
            <ProtectedRoute>
              <TaskList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-task"
          element={
            <ProtectedRoute>
              <AddTask />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-reward"
          element={
            <ProtectedRoute>
              <AddReward />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/generate-link"
          element={
            <ProtectedRoute>
              <GenerateProfileLink />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/family"
          element={
            <ProtectedRoute>
              <FamilyAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserPage"
          element={
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/task"
          element={
            <ProtectedRoute>
              <TaskAdmin />
            </ProtectedRoute>
          }
        />
                <Route
          path="/admin/reward"
          element={
            <ProtectedRoute>
              <RewardAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rewards"
          element={
            <ProtectedRoute>
              <RewardList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-selection"
          element={
            <ProtectedRoute>
              <UserSelection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/task-approvals"
          element={
            <ProtectedRoute>
              <TaskApproval />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/tile"
          element={
            <ProtectedRoute>
              <AdminTile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reward-approvals"
          element={
            <ProtectedRoute>
              <RewardApproval />
            </ProtectedRoute>
          }
        />
        <Route path="/token-login" element={<TokenLogin />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/auto-login" element={<AutoLogin />} />
      </Routes>
    </>
  );
}

export default App;
