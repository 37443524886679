import { initializeApp } from 'firebase/app';
import { getAuth, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB51pJUQE8Jpy5Ggqv2uLTsel1q5RKhaCM",
  authDomain: "localhost",
  projectId: "tasktribe-a3bc9",
  //storageBucket: "YOUR_STORAGE_BUCKET",
  //messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
  //appId: "YOUR_APP_ID"
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

const logout = () => {
  return signOut(auth);
};

const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export { auth, db, logout, login };