import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();


  const handleLogoClick = () => {
    navigate('/user-selection');
  };




  return (
    <nav className="bg-gray-800 p-4 text-white">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:justify-between">
        <div
          className="flex items-center cursor-pointer mb-4 md:mb-0 select-none"
          onClick={handleLogoClick}
          >
          <img src="/logo.webp" alt="TaskTribe" className="h-8 w-auto mr-2" draggable="false" />
          <h1 className="text-xl">TaskTribe</h1>
          
        </div>

        


      </div>
    </nav>
  );
};

export default Navigation;
