import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';

const AddTask = () => {
  const { familyId } = useAuth();
  const [taskName, setTaskName] = useState('');
  const [recurrence, setRecurrence] = useState('one-time');
  const [assignedTo, setAssignedTo] = useState('');
  const [points, setPoints] = useState(1);
  const [visibilityStart, setVisibilityStart] = useState('00:00');
  const [visibilityEnd, setVisibilityEnd] = useState('23:59');
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [usersAndGroups, setUsersAndGroups] = useState([]);

  useEffect(() => {
    const fetchUsersAndGroups = async () => {
      const usersSnapshot = await getDocs(collection(db, `families/${familyId}/users`));
      const groupsSnapshot = await getDocs(collection(db, `families/${familyId}/groups`));
      const users = usersSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      const groups = groupsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name, isGroup: true }));
      setUsersAndGroups([...users.filter(u => u.name), ...groups]);
    };

    fetchUsersAndGroups();
  }, [familyId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, `families/${familyId}/tasks`), {
        name: taskName,
        recurrence,
        assignedTo,
        points,
        visibilityStart,
        visibilityEnd,
        approvalRequired,
        isGroupTask: !!usersAndGroups.find(ug => ug.id === assignedTo && ug.isGroup),
        nextOccurrence: new Date()
      });
      setTaskName('');
      setRecurrence('one-time');
      setAssignedTo('');
      setPoints(1);
      setVisibilityStart('00:00');
      setVisibilityEnd('23:59');
      setApprovalRequired(false);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Add Task</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="taskName" className="block mb-2">Task Name</label>
          <input
            type="text"
            id="taskName"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="recurrence" className="block mb-2">Recurrence</label>
          <select
            id="recurrence"
            value={recurrence}
            onChange={(e) => setRecurrence(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          >
            <option value="one-time">One-Time</option>
            <option value="daily">Daily</option>
            <option value="6thHour">6thHour</option>
            <option value="every-other-day">Every Other Day</option>
            <option value="weekly">Weekly</option>
            <option value="bi-weekly">Bi-Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="assignedTo" className="block mb-2">Assign To</label>
          <select
            id="assignedTo"
            value={assignedTo}
            onChange={(e) => setAssignedTo(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          >
            <option value="">Select User or Group</option>
            {usersAndGroups.map(ug => (
              <option key={ug.id} value={ug.id}>
                {ug.isGroup ? `Group: ${ug.name}` : `User: ${ug.name}`}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="points" className="block mb-2">Points</label>
          <input
            type="number"
            id="points"
            value={points}
            onChange={(e) => setPoints(Math.max(1, Math.min(10, parseInt(e.target.value, 10))))}
            className="p-2 border border-gray-300 rounded w-full"
            min="1"
            max="10"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="visibilityStart" className="block mb-2">Visibility Start Time</label>
          <input
            type="time"
            id="visibilityStart"
            value={visibilityStart}
            onChange={(e) => setVisibilityStart(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="visibilityEnd" className="block mb-2">Visibility End Time</label>
          <input
            type="time"
            id="visibilityEnd"
            value={visibilityEnd}
            onChange={(e) => setVisibilityEnd(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Approval Required</label>
          <input
            type="checkbox"
            checked={approvalRequired}
            onChange={(e) => setApprovalRequired(e.target.checked)}
            className="p-2 border border-gray-300 rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add Task</button>
      </form>
    </div>
  );
};

export default AddTask;
