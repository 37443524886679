// Rewards.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Add this import
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, doc, updateDoc, addDoc, getDoc, onSnapshot, query} from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import useInactivityTimer from '../../hooks/useInactivityTimer';
import { FaClock } from 'react-icons/fa';
import { accreditPoints } from '../../services/pointsService'; // Correct casing
import { FaPlus, FaMinus } from 'react-icons/fa';


let rewardMultiple = 1;
const RewardList = () => {
  const { familyId, selectedUserId } = useAuth();
  const [rewards, setrewards] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userName, setUserName] = useState('');
  const [pressingrewardId, setPressingrewardId] = useState(null);



  const pressTimeoutRef = useRef(null);

  useInactivityTimer(30000);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const userDoc = await getDoc(doc(db, `families/${familyId}/users`, selectedUserId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserGroups(userData.group ? [userData.group] : []); // Set user groups
          setUserName(userData.name); // Set the user's name
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    if (selectedUserId) {
      fetchUserGroups();
    }
  }, [familyId, selectedUserId]);

  useEffect(() => {
    if (!familyId || !selectedUserId) return;

    const rewardsQuery = query(
      collection(db, `families/${familyId}/rewards`)
    );

    const unsubscribe = onSnapshot(rewardsQuery, (snapshot) => {
      const rewardsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredrewards = rewardsData.filter(reward => {
        const isPersonalreward = reward.assignedTo === selectedUserId;
        return isPersonalreward;
      });

      setrewards(filteredrewards);
    });

    return () => unsubscribe();
  }, [familyId, selectedUserId, userGroups]);

  const handlePerformreward = async (reward) => {
    try {
      // Add log entry to reward logs
      const logEntry = {
        rewardId: reward.id,
        userId: selectedUserId,
        timestamp: Timestamp.now(),
        points: reward.points * rewardMultiple || 0 // Ensure points is defined
      };

      await addDoc(collection(db, `families/${familyId}/rewardLogs`), logEntry);

      // Update the user points only if reward does not require approval
      if (!reward.approvalRequired) {
        await accreditPoints(familyId, selectedUserId, reward.points || 0);
      }

      // Update reward document with performedBy field and status
      await updateDoc(doc(db, `families/${familyId}/rewards`, reward.id), {
        performedBy: selectedUserId,
        status: reward.approvalRequired ? 'pendingApproval' : 'done',
        multiplier: rewardMultiple || 1
      });
    } catch (error) {
      console.error('Error performing reward:', error);
    }
  };

  const handlePressStart = (reward) => {
    setPressingrewardId(reward.id);
    pressTimeoutRef.current = setTimeout(() => handlePerformreward(reward), 2000); // 2 seconds long press
  };

  const handlePressEnd = () => {
    clearTimeout(pressTimeoutRef.current);
    setPressingrewardId(null);
  };

  const handleIncrement = (value) => {
    const newRewardMultiple = rewardMultiple + value;
    
    // Ensure rewardMultiple is always 1 or higher
    if (newRewardMultiple >= 1) {
      rewardMultiple = newRewardMultiple;
    }
    console.log(rewardMultiple);
  };


  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Rewards for {userName}</h1> {/* Display the user's name */}
      <ul>
          {rewards.map(reward => (
              <li
                  key={reward.id}
                  className={`task-item mb-2 p-2 border border-gray-300 rounded flex justify-between items-center ${pressingrewardId === reward.id ? 'long-press-active' : ''} ${reward.status === 'pendingApproval' ? 'bg-orange-400 cursor-not-allowed' : ''}`}
                  onMouseDown={() => handlePressStart(reward)}
                  onMouseUp={handlePressEnd}
                  onMouseLeave={handlePressEnd}
                  onTouchStart={() => handlePressStart(reward)}
                  onTouchEnd={handlePressEnd}
                  style={{ userSelect: 'none' }} // Prevent text selection
              >
                  <div>
                      <p><strong>reward:</strong> {reward.name}</p>
                      <p><strong>Points:</strong> {reward.points}</p>
                  </div>
                  {reward.status === 'pendingApproval' && <FaClock className="reward-icon" />}
                  {reward.multipliable && (
                      <div>

                         
                         <button style={{ marginRight: '20px' , marginLeft: '20px' }} onClick={() => handleIncrement(1)}><FaPlus /></button> {/* Plus Icon */}
                         <button style={{ marginRight: '20px' , marginLeft: '20px' }} onClick={() => handleIncrement(-1)}><FaMinus /></button> {/* Minus Icon */}
                         
                         <span style={{ marginRight: '20px' , marginLeft: '20px' }}>{rewardMultiple}</span>
                      </div>
                  )}
              </li>
          ))}
      </ul>
      
      <div className="fixed bottom-4 right-4">
        <Link to="/profile" className="bg-blue-500 text-white p-2 rounded">Profile</Link>
      </div>
    </div>
  );
};

export default RewardList;
