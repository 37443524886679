import { Link } from 'react-router-dom';

const Tile = ({ title, path, color }) => {
    return (
        <Link to={path} style={{ textDecoration: 'none' }}>
            <div style={{ border: '2px solid #333', borderRadius: '8px', padding: '20px', margin: '10px', minWidth: '200px', textAlign: 'center', backgroundColor: color }}>
                <h3>{title}</h3>
            </div>
            <div>
                
            </div>
        </Link>
    );
};

export default Tile;
