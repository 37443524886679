import Tile from '../Composables/Tile';
import { useAuth } from '../../context/AuthContext';





const AdminTile = () => {

    const { logout } = useAuth();

    return (

            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <Tile title="Add Task" path="/add-task"  color='#5b7af7'/>
                <Tile title="Task Admin" path="/admin/task" color='#5b7af7'/>
                <Tile title="Task Approval" path="/admin/task-approvals" color='#5b7af7'/>
                <Tile title="Add Reward" path="/add-reward"  color='#33ff52'/>
                <Tile title="Reward Admin" path="/admin/reward" color='#33ff52'/>
                <Tile title="Reward Approval" path="/admin/reward-approvals" color='#33ff52'/>
                <Tile title="Family Admin" path="/admin/family" color='#ffb433'/>
                <div 
                    onClick={logout} 
                    style={{ border: '2px solid #333', borderRadius: '8px', padding: '20px', margin: '10px', minWidth: '200px', textAlign: 'center', backgroundColor: '#f13b3b'}}>
                    <h3>Logout</h3>
                </div>
            </div>
            

    );
};

export default AdminTile;
