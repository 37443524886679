import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Import the useAuth hook
import { db } from '../../services/firebase';
import { collection, getDocs, updateDoc, query, where, doc, getDoc } from 'firebase/firestore';
import { accreditPoints } from '../../services/pointsService';

const RewardApproval = () => {
  const { familyId } = useAuth(); // Get the familyId from the authenticated user context
  const [rewards, setrewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userNames, setUserNames] = useState({});

  useEffect(() => {
    const fetchUserNames = async () => {
      const usersSnapshot = await getDocs(collection(db, `families/${familyId}/users`));
      const userData = {};
      usersSnapshot.forEach(doc => {
        userData[doc.id] = doc.data().name;
      });
      setUserNames(userData);
    };

const fetchrewards = async () => {
    try {
        const q = query(collection(db, `families/${familyId}/rewards`), where('status', '==', 'pendingApproval'));
        const querySnapshot = await getDocs(q);

        const rewardsData = querySnapshot.docs.map(doc => {
            const data = doc.data();
            // Adding a new field to each reward that multiplies points with the multiplier
            const newReward = {
                id: doc.id,
                ...data,
                multipliedPoints: data.points * data.multiplier
            };
            return newReward;
        });

        setrewards(rewardsData);
    } catch (error) {
        console.error('Error fetching rewards:', error);
    } finally {
        setLoading(false);
    }
};


    if (familyId) {
      fetchUserNames();
      fetchrewards();
    }
  }, [familyId]);

  const handleApprovereward = async (reward) => {
    try {
      const userRef = doc(db, `families/${familyId}/users/${reward.performedBy}`);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        console.error('User document does not exist');
        return;
      }

      await accreditPoints(familyId, reward.performedBy, reward.multipliedPoints);

      await updateDoc(doc(db, `families/${familyId}/rewards`, reward.id), {
        status: 'done',
      });

      setrewards(prevrewards => prevrewards.filter(t => t.id !== reward.id));
    } catch (error) {
      console.error('Error approving reward:', error);
    }
  };

  const handleRejectreward = async (reward) => {
    try {
      await updateDoc(doc(db, `families/${familyId}/rewards`, reward.id), {
        status: 'pending'
      });

      setrewards(prevrewards => prevrewards.filter(t => t.id !== reward.id));
    } catch (error) {
      console.error('Error rejecting reward:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Reward Approvals</h1>
      <ul>
        {rewards.map(reward => (
          <li key={reward.id} className="mb-2 p-2 border border-gray-300 rounded flex justify-between items-center">
            <div>
              <p><strong>reward:</strong> {reward.name}</p>
              <p><strong>Performed By:</strong> {userNames[ reward.performedBy] || reward.performedBy}</p>
              <p><strong>Points:</strong> {reward.multipliedPoints}</p>
              <p><strong>Recurrence:</strong> {reward.recurrence}</p>
            </div>
            <div className="flex space-x-2">
              <button onClick={() => handleApprovereward(reward)} className="bg-green-500 text-white p-2 rounded">Approve</button>
              <button onClick={() => handleRejectreward(reward)} className="bg-red-500 text-white p-2 rounded">Reject</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RewardApproval;
