// TaskList.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Add this import
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, doc, updateDoc, addDoc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import useInactivityTimer from '../../hooks/useInactivityTimer';
import { FaClock } from 'react-icons/fa';
import { calculateNextOccurrence } from '../../services/nextOccurrenceService';
import { accreditPoints } from '../../services/pointsService'; // Correct casing

const TaskList = () => {
  const { familyId, selectedUserId } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userName, setUserName] = useState('');
  const [pressingTaskId, setPressingTaskId] = useState(null);

  const pressTimeoutRef = useRef(null);

  useInactivityTimer(30000);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const userDoc = await getDoc(doc(db, `families/${familyId}/users`, selectedUserId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserGroups(userData.group ? [userData.group] : []); // Set user groups
          setUserName(userData.name); // Set the user's name
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    if (selectedUserId) {
      fetchUserGroups();
    }
  }, [familyId, selectedUserId]);

  useEffect(() => {
    if (!familyId || !selectedUserId) return;

    const tasksQuery = query(
      collection(db, `families/${familyId}/tasks`),
      where('nextOccurrence', '<=', Timestamp.now())
    );

    const unsubscribe = onSnapshot(tasksQuery, (snapshot) => {
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredTasks = tasksData.filter(task => {
          const isPersonalTask = task.assignedTo === selectedUserId;
          const isGroupTask = task.isGroupTask && userGroups.includes(task.assignedTo);
          
          // Exclude tasks with status "done"
          const isNotDone = task.status !== 'done';
      
          return (isPersonalTask || isGroupTask) && isNotDone;
      });
      

      setTasks(filteredTasks);
    });

    return () => unsubscribe();
  }, [familyId, selectedUserId, userGroups]);

  const handlePerformTask = async (task) => {
    try {
      // Add log entry to task logs
      const logEntry = {
        taskId: task.id,
        userId: selectedUserId,
        timestamp: Timestamp.now(),
        points: task.points || 0 // Ensure points is defined
      };

      await addDoc(collection(db, `families/${familyId}/taskLogs`), logEntry);

      // Update the user points only if task does not require approval
      if (!task.approvalRequired) {
        await accreditPoints(familyId, selectedUserId, task.points || 0);
      }

      // Update task document with performedBy field and status
      await updateDoc(doc(db, `families/${familyId}/tasks`, task.id), {
        performedBy: selectedUserId,
        status: task.approvalRequired ? 'pendingApproval' : 'done',
        nextOccurrence: task.approvalRequired ? task.nextOccurrence : calculateNextOccurrence(task.recurrence)
      });
    } catch (error) {
      console.error('Error performing task:', error);
    }
  };

  const handlePressStart = (task) => {
    setPressingTaskId(task.id);
    pressTimeoutRef.current = setTimeout(() => handlePerformTask(task), 2000); // 2 seconds long press
  };

  const handlePressEnd = () => {
    clearTimeout(pressTimeoutRef.current);
    setPressingTaskId(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Tasks for {userName}</h1> {/* Display the user's name */}
      <ul>
        {tasks.map(task => (
          <li
            key={task.id}
            className={`task-item mb-2 p-2 border border-gray-300 rounded flex justify-between items-center ${pressingTaskId === task.id ? 'long-press-active' : ''} ${task.status === 'pendingApproval' ? 'bg-orange-400 cursor-not-allowed' : ''}`}
            onMouseDown={() => handlePressStart(task)}
            onMouseUp={handlePressEnd}
            onMouseLeave={handlePressEnd}
            onTouchStart={() => handlePressStart(task)}
            onTouchEnd={handlePressEnd}
            style={{ userSelect: 'none' }} // Prevent text selection
          >
            <div>
              <p><strong>Task:</strong> {task.name}</p>
              <p><strong>Points:</strong> {task.points}</p>
              <p><strong>Recurrence:</strong> {task.recurrence}</p>
            </div>
            {task.status === 'pendingApproval' && <FaClock className="task-icon" />}
          </li>
        ))}
      </ul>
      <div className="fixed bottom-4 right-4">
        <Link to="/profile" className="bg-blue-500 text-white p-2 rounded">Profile</Link>
      </div>
    </div>
  );
};

export default TaskList;
