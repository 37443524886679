import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const TokenLogin = () => {
  const { loginWithToken } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      const verifyToken = async () => {
        try {
          await loginWithToken(token);
          navigate('/profile'); // Redirect to profile after successful login
        } catch (error) {
          console.error("Error verifying token:", error);
        }
      };

      verifyToken();
    }
  }, [loginWithToken, navigate, searchParams]);

  return (
    <div>
      <h1>Verifying token...</h1>
    </div>
  );
};

export default TokenLogin;
