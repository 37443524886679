import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';

const FamilyAdmin = () => {
  const { familyId } = useAuth();
  const [userName, setUserName] = useState('');

  const [userGroup, setUserGroup] = useState('');
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupMap, setGroupMap] = useState({});
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    const fetchUsersAndGroups = async () => {
      try {
        const usersQuery = collection(db, `families/${familyId}/users`);
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersData);

        const groupsQuery = collection(db, `families/${familyId}/groups`);
        const groupsSnapshot = await getDocs(groupsQuery);
        const groupsData = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGroups(groupsData);

        const groupLookup = {};
        groupsData.forEach(group => {
          groupLookup[group.id] = group.name;
        });
        setGroupMap(groupLookup);
      } catch (error) {
        console.error('Error fetching users and groups:', error);
      }
    };

    fetchUsersAndGroups();
  }, [familyId]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, `families/${familyId}/users`), {
        name: userName,

        group: userGroup,
      });

      setUserName('');

      setUserGroup('');

      // Refresh users list
      const usersQuery = collection(db, `families/${familyId}/users`);
      const usersSnapshot = await getDocs(usersQuery);
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);

    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    try {
      const groupRef = await addDoc(collection(db, `families/${familyId}/groups`), {
        name: newGroupName,
      });

      const newGroup = { id: groupRef.id, name: newGroupName };
      setGroups([...groups, newGroup]);
      setGroupMap({ ...groupMap, [groupRef.id]: newGroupName });
      setNewGroupName('');
    } catch (error) {
      console.error('Error adding group:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, `families/${familyId}/users`, userId));

      // Refresh users list
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Family Administration</h1>
      <form onSubmit={handleAddUser} className="mb-4">
        <div className="mb-2">
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-700">Group</label>
          <select
            value={userGroup}
            onChange={(e) => setUserGroup(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select Group</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.name}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add User</button>
      </form>

      <form onSubmit={handleAddGroup} className="mb-4">
        <div className="mb-2">
          <label className="block text-gray-700">New Group Name</label>
          <input
            type="text"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <button type="submit" className="bg-green-500 text-white p-2 rounded">Add Group</button>
      </form>

      <h2 className="text-xl mb-4">Current Users</h2>
      <ul>
        {users.filter(user => user.name).map(user => (
          <li key={user.id} className="mb-2 p-2 border border-gray-300 rounded flex justify-between items-center">
            <div>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Group:</strong> {groupMap[user.group] || 'None'}</p>
            </div>
            <button
              onClick={() => handleDeleteUser(user.id)}
              className="bg-red-500 text-white p-2 rounded"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FamilyAdmin;
