import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

const TaskAdmin = () => {
  const { familyId } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchTasksUsersAndGroups = async () => {
      try {
        const tasksQuery = collection(db, `families/${familyId}/tasks`);
        const tasksSnapshot = await getDocs(tasksQuery);
        const tasksData = tasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasks(tasksData);

        const usersQuery = collection(db, `families/${familyId}/users`);
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.name);
        setUsers(usersData);

        const groupsQuery = collection(db, `families/${familyId}/groups`);
        const groupsSnapshot = await getDocs(groupsQuery);
        const groupsData = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGroups(groupsData);
      } catch (error) {
        console.error('Error fetching tasks, users, and groups:', error);
      }
    };

    fetchTasksUsersAndGroups();
  }, [familyId]);

  const handleUpdateTask = async (task) => {
    try {
      const isGroupTask = !!groups.find(group => group.id === task.assignedTo);
      await updateDoc(doc(db, `families/${familyId}/tasks`, task.id), { ...task, isGroupTask });
      const tasksQuery = collection(db, `families/${familyId}/tasks`);
      const tasksSnapshot = await getDocs(tasksQuery);
      const tasksData = tasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await deleteDoc(doc(db, `families/${familyId}/tasks`, taskId));
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleInputChange = (e, taskId, field) => {
    const value = e.target.value;
    setTasks(tasks.map(t => t.id === taskId ? { ...t, [field]: value } : t));
  };

  const handleDateChange = (e, taskId, field) => {
    const value = e.target.value;
    try {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      setTasks(tasks.map(t => t.id === taskId ? { ...t, [field]: Timestamp.fromDate(date) } : t));
    } catch (error) {
      console.error('Invalid date value:', error);
    }
  };

  const handleCheckboxChange = (e, taskId, field) => {
    const value = e.target.checked;
    setTasks(tasks.map(t => t.id === taskId ? { ...t, [field]: value } : t));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Task Administration</h1>
      <ul>
        {tasks.map(task => (
          <li key={task.id} className="mb-2 p-2 border border-gray-300 rounded flex flex-col">
            <div className="flex justify-between items-center">
              <strong>Task Name:</strong>
              <input
                type="text"
                value={task.name || ''}
                onChange={(e) => handleInputChange(e, task.id, 'name')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <strong>Recurrence:</strong>
              <select
                value={task.recurrence || 'one-time'}
                onChange={(e) => handleInputChange(e, task.id, 'recurrence')}
                className="ml-2 p-1 border border-gray-300 rounded"
              >
                <option value="one-time">One-Time</option>
                <option value="daily">Daily</option>
                <option value="6thHour">6thHour</option>
                <option value="every-other-day">Every Other Day</option>
                <option value="weekly">Weekly</option>
                <option value="bi-weekly">Bi-Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
            <div className="flex justify-between items-center">
              <strong>Assign To:</strong>
              <select
                value={task.assignedTo || ''}
                onChange={(e) => handleInputChange(e, task.id, 'assignedTo')}
                className="ml-2 p-1 border border-gray-300 rounded"
              >
                <option value="">Select User or Group</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>User: {user.name}</option>
                ))}
                {groups.map(group => (
                  <option key={group.id} value={group.id}>Group: {group.name}</option>
                ))}
              </select>
            </div>
            <div className="flex justify-between items-center">
              <strong>Points:</strong>
              <input
                type="number"
                value={task.points || 1}
                onChange={(e) => handleInputChange(e, task.id, 'points')}
                className="ml-2 p-1 border border-gray-300 rounded"
                min="1"
                max="10"
              />
            </div>
            <div className="flex justify-between items-center">
              <strong>Visibility Start Time:</strong>
              <input
                type="time"
                value={task.visibilityStart || ''}
                onChange={(e) => handleInputChange(e, task.id, 'visibilityStart')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <strong>Visibility End Time:</strong>
              <input
                type="time"
                value={task.visibilityEnd || ''}
                onChange={(e) => handleInputChange(e, task.id, 'visibilityEnd')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <strong>Next Occurrence:</strong>
              <input
                type="datetime-local"
                value={task.nextOccurrence ? new Date(task.nextOccurrence.seconds * 1000).toLocaleString('sv-SE', { timeZone: 'CET', hour12: false }).replace(' ', 'T').slice(0, 16) : ''}
                onChange={(e) => handleDateChange(e, task.id, 'nextOccurrence')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <strong>Approval Required:</strong>
              <input
                type="checkbox"
                checked={task.approvalRequired || false}
                onChange={(e) => handleCheckboxChange(e, task.id, 'approvalRequired')}
                className="ml-2 p-1 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between items-center mt-2">
              <button
                onClick={() => handleUpdateTask(task)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Update Task
              </button>
              <button
                onClick={() => handleDeleteTask(task.id)}
                className="bg-red-500 text-white p-2 rounded"
              >
                Delete Task
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskAdmin;
