// nextOccurrenceService.js

import { Timestamp } from 'firebase/firestore';

// Function to calculate the next occurrence based on the provided recurrence type
export const calculateNextOccurrence = (recurrence) => {
  let nextOccurrence = new Date();
  
  // Set hours to 00:00 for consistency across cases (commented out initially)
  //nextOccurrence.setHours(0, 0, 0, 0); 

  switch (recurrence) {
    case 'daily':
      // For daily recurrence, set time to midnight and add 1 day
      nextOccurrence.setHours(0, 0, 0, 0);
      nextOccurrence.setDate(nextOccurrence.getDate() + 1);
      break;
    case '6thHour':
      // Add 6 hours for every 6th hour recurrence
      nextOccurrence.setHours(nextOccurrence.getHours() + 6);
      break;
    case 'every-other-day':
      // For every other day, set time to midnight and add 2 days
      nextOccurrence.setHours(0, 0, 0, 0);
      nextOccurrence.setDate(nextOccurrence.getDate() + 2);
      break;
    case 'weekly':
      // For weekly recurrence, set time to midnight and add 7 days
      nextOccurrence.setHours(0, 0, 0, 0);
      nextOccurrence.setDate(nextOccurrence.getDate() + 7);
      break;
    case 'bi-weekly':
      // For bi-weekly recurrence, set time to midnight and add 14 days
      nextOccurrence.setHours(0, 0, 0, 0);
      nextOccurrence.setDate(nextOccurrence.getDate() + 14);
      break;
    case 'monthly':
      // For monthly recurrence, set time to midnight and add 1 month
      nextOccurrence.setHours(0, 0, 0, 0);
      nextOccurrence.setMonth(nextOccurrence.getMonth() + 1);
      break;
    case 'custom-hours':
      // Set custom hours based on user input
      nextOccurrence.setHours(nextOccurrence.getHours() + recurrence.hours);
      break;
    default:
      // Reset to current date at midnight if no valid recurrence provided
      nextOccurrence = new Date();
      nextOccurrence.setHours(0, 0, 0, 0);
  }
  
  return Timestamp.fromDate(nextOccurrence);
};
