import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Import the useAuth hook
import { db } from '../../services/firebase';
import { collection, getDocs, updateDoc, query, where, doc, getDoc } from 'firebase/firestore';
import { calculateNextOccurrence } from '../../services/nextOccurrenceService';
import { accreditPoints } from '../../services/pointsService';

const TaskApproval = () => {
  const { familyId } = useAuth(); // Get the familyId from the authenticated user context
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userNames, setUserNames] = useState({});

  useEffect(() => {
    const fetchUserNames = async () => {
      const usersSnapshot = await getDocs(collection(db, `families/${familyId}/users`));
      const userData = {};
      usersSnapshot.forEach(doc => {
        userData[doc.id] = doc.data().name;
      });
      setUserNames(userData);
    };

    const fetchTasks = async () => {
      try {
        const q = query(collection(db, `families/${familyId}/tasks`), where('status', '==', 'pendingApproval'));
        const querySnapshot = await getDocs(q);

        const tasksData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasks(tasksData);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    if (familyId) {
      fetchUserNames();
      fetchTasks();
    }
  }, [familyId]);

  const handleApproveTask = async (task) => {
    try {
      const userRef = doc(db, `families/${familyId}/users/${task.performedBy}`);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        console.error('User document does not exist');
        return;
      }

      await accreditPoints(familyId, task.performedBy, task.points);
      let statusType = "";
      if(task.recurrence === "one-time"){
        statusType = 'done'
      }
      else {
        statusType = 'pending'
      };
      const nextOccurrence = calculateNextOccurrence(task.recurrence);
      await updateDoc(doc(db, `families/${familyId}/tasks`, task.id), {
        
        status: statusType,
        nextOccurrence: nextOccurrence
      });

      setTasks(prevTasks => prevTasks.filter(t => t.id !== task.id));
    } catch (error) {
      console.error('Error approving task:', error);
    }
  };

  const handleRejectTask = async (task) => {
    try {
      await updateDoc(doc(db, `families/${familyId}/tasks`, task.id), {
        status: 'pending'
      });

      setTasks(prevTasks => prevTasks.filter(t => t.id !== task.id));
    } catch (error) {
      console.error('Error rejecting task:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Task Approvals</h1>
      <ul>
        {tasks.map(task => (
          <li key={task.id} className="mb-2 p-2 border border-gray-300 rounded flex justify-between items-center">
            <div>
              <p><strong>Task:</strong> {task.name}</p>
              <p><strong>Performed By:</strong> {userNames[ task.performedBy] || task.performedBy}</p>
              <p><strong>Points:</strong> {task.points}</p>
              <p><strong>Recurrence:</strong> {task.recurrence}</p>
            </div>
            <div className="flex space-x-2">
              <button onClick={() => handleApproveTask(task)} className="bg-green-500 text-white p-2 rounded">Approve</button>
              <button onClick={() => handleRejectTask(task)} className="bg-red-500 text-white p-2 rounded">Reject</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskApproval;
