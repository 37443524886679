import React, { useEffect, useState } from 'react';
import { db } from '../services/firebase';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, updateDoc, deleteField, collection, query, where, getDocs } from 'firebase/firestore';

const Profile = () => {
  const { familyId, selectedUserId } = useAuth();
  const [userData, setUserData] = useState(null);
  const [goalName, setGoalName] = useState('');
  const [goalPoints, setGoalPoints] = useState('');
  const [showAddGoalForm, setShowAddGoalForm] = useState(false);
  const [showTaskLog, setShowTaskLog] = useState(false);
  const [taskLog, setTaskLog] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, `families/${familyId}/users/${selectedUserId}`);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [familyId, selectedUserId]);

  useEffect(() => {
    const fetchTaskLog = async () => {
      try {
        const taskLogQuery = query(
          collection(db, `families/${familyId}/taskLogs`),
          where('userId', '==', selectedUserId)
        );
        const taskLogSnapshot = await getDocs(taskLogQuery);
        const taskLogData = taskLogSnapshot.docs.map(doc => doc.data());
        setTaskLog(taskLogData);
      } catch (error) {
        console.error('Error fetching task log:', error);
      }
    };

    if (showTaskLog) {
      fetchTaskLog();
    }
  }, [familyId, selectedUserId, showTaskLog]);

  const handleAddGoal = async () => {
    if (goalName && goalPoints) {
      try {
        const userDocRef = doc(db, `families/${familyId}/users/${selectedUserId}`);
        await updateDoc(userDocRef, {
          goal: {
            name: goalName,
            points: parseInt(goalPoints, 10),
            progress: 0
          }
        });
        setUserData({ ...userData, goal: { name: goalName, points: parseInt(goalPoints, 10), progress: 0 } });
        setShowAddGoalForm(false);
        setGoalName('');
        setGoalPoints('');
      } catch (error) {
        console.error('Error adding goal:', error);
      }
    }
  };

  const handleDeleteGoalWithConfirmation = () => {
    const shouldDelete = window.confirm("Are you sure you want to delete this goal?");
    
    if (shouldDelete) {
      // Proceed with deleting the goal
      handleDeleteGoal();
    } else {
      // Optionally handle cancellation or do nothing
      console.log("Deletion canceled.");
    }
  };

  const handleDeleteGoal = async () => {
    try {
      const userDocRef = doc(db, `families/${familyId}/users/${selectedUserId}`);
      await updateDoc(userDocRef, {
        goal: deleteField()
      });
      setUserData({ ...userData, goal: null });
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  const progressPercentage = userData?.goal
    ? Math.min((userData.points / userData.goal.points) * 100, 100)
    : 0;

  const minVisibleWidth = progressPercentage > 0 && progressPercentage < 5 ? 5 : progressPercentage;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">{userData?.name}'s Profile</h1>
      <p>Points: {userData?.points}</p>

      {userData?.goal ? (
        <div className="my-4">
          <h2 className="text-xl mb-2">Goal: {userData.goal.name}</h2>
          <div className="w-full bg-gray-200 rounded-full h-6 overflow-hidden relative">
            <div
              className="bg-green-500 h-full text-center text-white"
              style={{ width: `${minVisibleWidth}%` }}
              title={`${progressPercentage.toFixed(1)}%`}
            >
              {progressPercentage >= 5 ? `${progressPercentage.toFixed(1)}%` : ''}
            </div>
          </div>
          <button
            onClick={handleDeleteGoalWithConfirmation}
            className="mt-4 bg-red-500 text-white p-2 rounded"
          >
            Delete Goal
          </button>
          <button
            onClick={() => setShowTaskLog(!showTaskLog)}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            {showTaskLog ? 'Hide' : 'Show'} Task Log
          </button>
        </div>
      ) : (
        <div>
          <button
            onClick={() => setShowAddGoalForm(true)}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Add Goal
          </button>
          {showAddGoalForm && (
            <div className="mt-4">
              <input
                type="text"
                placeholder="Goal Name"
                value={goalName}
                onChange={(e) => setGoalName(e.target.value)}
                className="p-2 border border-gray-300 rounded mb-2"
              />
              <input
                type="number"
                placeholder="Goal Points"
                value={goalPoints}
                onChange={(e) => setGoalPoints(e.target.value)}
                className="p-2 border border-gray-300 rounded mb-2"
              />
              <button
                onClick={handleAddGoal}
                className="bg-green-500 text-white p-2 rounded"
              >
                Save Goal
              </button>
            </div>
          )}
        </div>
      )}

      {showTaskLog && (
        <div className="mt-4">
          <h2 className="text-xl mb-2">Task Log</h2>
          <ul>
            {taskLog.map((log, index) => (
              <li key={index} className="mb-2 p-2 border border-gray-300 rounded">
                <p><strong>Task:</strong> {log.taskId}</p>
                <p><strong>Date:</strong> {new Date(log.timestamp.seconds * 1000).toLocaleDateString()}</p>
                <p><strong>Points:</strong> {log.points}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;
