
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Add this import


const UserPage = () => {
  const { selectedUserId } = useAuth();
  
  const { setSelectedUserId } = useAuth();
  const navigate = useNavigate();



  const handleUserSelect = (userId, page) => {
    setSelectedUserId(userId);

    navigate('/' + page);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
       
          <button
            key={'tasks'}
            onClick={() => handleUserSelect(selectedUserId, 'tasks')}
            className="bg-blue-500 text-white p-4 rounded-lg text-lg w-full"
          >
            Tasks
          </button>
          <button
            key={'rewards'}
            onClick={() => handleUserSelect(selectedUserId, 'rewards')}
            className="bg-blue-500 text-white p-4 rounded-lg text-lg w-full"
          >
            Rewards
          </button>
      </div>
      <div className="fixed bottom-4 right-4">
        <Link to="/profile" className="bg-blue-500 text-white p-2 rounded">Profile</Link>
      </div>
    </div>
  );
};

export default UserPage;
