import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../services/firebase';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [familyId, setFamilyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);

        const fetchUserDoc = async () => {
          try {
            const userDoc = await getDoc(doc(db, `families/${user.uid}/users`, user.uid));
            if (userDoc.exists()) {
              setFamilyId(userDoc.data().familyId);
              setLoading(false);
            } else {
              console.error("No such document!");
              setTimeout(fetchUserDoc, 1000);
            }
          } catch (error) {
            console.error("Error fetching user document:", error);
            setTimeout(fetchUserDoc, 1000);
          }
        };

        fetchUserDoc();
      } else {
        setCurrentUser(null);
        setFamilyId(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const register = async (email, password, name) => {
      try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
  
          const userDocRef = doc(db, `families/${user.uid}/users`, user.uid);
          
          // Set user document with setDoc
          await setDoc(userDocRef, {
              email: user.email,
              familyId: user.uid,
              points: 0,
          });
  
          const familyRef = doc(db, "families", user.uid);
  
          // Set family document with setDoc
          await setDoc(familyRef, {
              familyName: name,
          });
  
          setCurrentUser(user);
          setFamilyId(user.uid);
      } catch (error) {
          throw new Error(error.message);
      }
  };
  
  

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const value = {
    currentUser,
    familyId,
    selectedUserId,
    setSelectedUserId,
    loading,
    register,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
