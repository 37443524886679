import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/user-selection');
    } catch (error) {
      setError(`Failed to log in: ${error.message}`);
      console.error('Login error:', error);
    }
  };

  return (
   <div className="min-h-screen flex items-center justify-center bg-gray-100">
     <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
       <div className="text-center mb-6">
         <img src="/logo.webp" alt="TaskTribe" className="mx-auto h-16 w-auto" />
       </div>
       <h2 className="text-2xl font-bold mb-4 text-center">Log In</h2>
       {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">{error}</div>}
       <form onSubmit={handleSubmit}>
         <div className="mb-4">
           <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
           <input
             type="email"
             id="email"
             value={email}
             onChange={(e) => setEmail(e.target.value)}
             required
             className="mt-1 p-2 w-full border rounded"
           />
         </div>
         <div className="mb-6">
           <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
           <input
             type="password"
             id="password"
             value={password}
             onChange={(e) => setPassword(e.target.value)}
             required
             className="mt-1 p-2 w-full border rounded"
           />
         </div>
         <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600">Log In</button>
       </form>
       
       {/* Signup Link */}
       <div className="text-center mt-4">
         <span>Don't have an account? </span>
         <a href="/register" className="text-blue-500 hover:underline">Sign Up</a>
       </div>
     </div>
   </div>
   
  );
};

export default Login;
