import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../services/firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';

const AddReward = () => {
  const { familyId } = useAuth();
  const [rewardName, setRewardName] = useState('');

  const [assignedTo, setAssignedTo] = useState('');
  const [points, setPoints] = useState(1);

  const [approvalRequired, setApprovalRequired] = useState(false);
  const [usersAndGroups, setUsersAndGroups] = useState([]);
  const [multipliable, setMultipliable] = useState(false);

  useEffect(() => {
    const fetchUsersAndGroups = async () => {
      const usersSnapshot = await getDocs(collection(db, `families/${familyId}/users`));
      const groupsSnapshot = await getDocs(collection(db, `families/${familyId}/groups`));
      const users = usersSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      const groups = groupsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name, isGroup: true }));
      setUsersAndGroups([...users.filter(u => u.name), ...groups]);
    };

    fetchUsersAndGroups();
  }, [familyId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, `families/${familyId}/rewards`), {
        name: rewardName,
        assignedTo,
        points,
        approvalRequired,
        multipliable,
        nextOccurrence: new Date()
      });
      setRewardName('');
      setAssignedTo('');
      setPoints(1);
      setApprovalRequired(false);
      setMultipliable(false);
    } catch (error) {
      console.error('Error adding reward:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Add Reward</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="rewardName" className="block mb-2">Task Name</label>
          <input
            type="text"
            id="rewardName"
            value={rewardName}
            onChange={(e) => setRewardName(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="assignedTo" className="block mb-2">Assign To</label>
          <select
            id="assignedTo"
            value={assignedTo}
            onChange={(e) => setAssignedTo(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
          >
            <option value="">Select User or Group</option>
            {usersAndGroups.map(ug => (
              <option key={ug.id} value={ug.id}>
                {ug.isGroup ? `Group: ${ug.name}` : `User: ${ug.name}`}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="points" className="block mb-2">Points</label>
          <input
            type="number"
            id="points"
            value={points}
            onChange={(e) => setPoints(Math.max(1, Math.min(10, parseInt(e.target.value, 10))))}
            className="p-2 border border-gray-300 rounded w-full"
            min="1"
            max="10"
          />
        </div>
        <div className="mb-4">
        <label className="block mb-2 flex items-center cursor-pointer">
        <span className="mr-2">Approval Required</span>
          <input
            type="checkbox"
            checked={approvalRequired}
            onChange={(e) => setApprovalRequired(e.target.checked)}
            className="hidden" // Hide the default checkbox input
          />
          <span className="checkmark"></span> 
          </label>
        </div>
        <div className="mb-4">
            <label className="block mb-2 flex items-center cursor-pointer">
                <span className="mr-2">Multipliable</span>
                <input
                    type="checkbox"
                    checked={multipliable}
                    onChange={(e) => setMultipliable(e.target.checked)}
                    className="hidden" // Hide the default checkbox input
                    id="multipliable-checkbox"
                />
                <span className="checkmark"></span> 
            </label>
        </div>
        
        
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add Reward</button>
      </form>
    </div>
  );
};

export default AddReward;
