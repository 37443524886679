import { useEffect } from 'react';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';

const AutoLogin = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  useEffect(() => {
    if (token) {
      db.collectionGroup('profileLinks')
        .where('token', '==', token)
        .get()
        .then(snapshot => {
          if (!snapshot.empty) {
            snapshot.forEach(doc => {
              const data = doc.data();
              const { familyId, userId } = data;

              // Store user profile in local storage
              localStorage.setItem('selectedUserProfile', JSON.stringify({ familyId, userId }));
              navigate('/dashboard');
            });
          } else {
            console.error("Invalid or expired token");
          }
        }).catch(error => {
          console.error("Error during auto-login: ", error);
        });
    }
  }, [token, navigate]);

  return <div>Loading...</div>;
};

export default AutoLogin;
