import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { currentUser, familyId } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return React.cloneElement(children, { familyId });
};

export default ProtectedRoute;
